import { Application } from "@hotwired/stimulus"
const application = Application.start()

application.debug = false
window.Stimulus   = application

import ClickableElementController from "./clickable_element_controller"
application.register("clickable-element", ClickableElementController)

import DisableButtonController from "./disable_button_controller"
application.register("disable-button", DisableButtonController)

import SubmitFormController from "./submit_form_controller"
application.register("submit-form", SubmitFormController)

import FooterController from "./footer_controller"
application.register("footer", FooterController)

import Flatpickr from "./flatpickr_controller.js"
application.register("flatpickr", Flatpickr)

import FilterController from "./filter_controller"
application.register("filter", FilterController)

import BS2ModalController from "./bs_2_modal_controller"
application.register("bs-2-modal", BS2ModalController)

import BS5ModalController from "./bs_5_modal_controller"
application.register("bs-5-modal", BS5ModalController)

import SidedrawerController from "./sidedrawer_controller"
application.register("sidedrawer", SidedrawerController)

import CustomEventController from "./dispatch_event_controller"
application.register("dispatch-event", CustomEventController)

import DomManipulationController from "./dom_manipulations_controller"
application.register("dom-manipulations", DomManipulationController)

import TabLinksController from "./tab_links_controller"
application.register("tab-links", TabLinksController)

import TomSelectController from "./tom_select_controller"
application.register("tom-select", TomSelectController)

import ScrollIntoViewController from "./scroll_into_view_controller"
application.register("scroll-into-view", ScrollIntoViewController)

import SpinnerController from "./spinner_controller"
application.register("spinner", SpinnerController)

import FormTemplateController from "./form_template_controller"
application.register("form-template", FormTemplateController)

import AlertController from "./alert_controller"
application.register("alert", AlertController)

import QrScannerController from "./qr_scanner_controller"
application.register("qr-scanner", QrScannerController)

import PasswordInputController from "./password_input_controller"
application.register("password-input", PasswordInputController)

import ToastHandlerController from "./toast_handler_controller"
application.register("toast-handler", ToastHandlerController)

// Native
import NativeBridgeController from "./native/bridge_controller"
application.register("native--bridge", NativeBridgeController)

import StradaMenuController from "./native/menu_controller"
application.register("native--menu", StradaMenuController)

import StradaFormController from "./native/form_controller"
application.register("native--form", StradaFormController)

import OverflowMenuController from "./native/overflow_menu_controller"
application.register("native--overflow-menu", OverflowMenuController)

// Partials
import PartialIntNennungController from "./partials/int_nennung_controller"
application.register("partial--int-nennung", PartialIntNennungController)

import PartialAdminEinstellungenController from "./partials/admin_einstellungen_controller"
application.register("partial--admin-einstellungen", PartialAdminEinstellungenController)
